<template>
  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :id="$route.params.id"
    pathPrefix="civil"
    :showDeleteButton="!(isCustomer || isSuperCustomer)"
    :headerData="{ civilCase, lawyer }"
  >
    <template v-slot:formHeader="{ civilCase, lawyer }">
      <div class="vx-row" v-if="civilCase">
        <div class="vx-col sm:w-1/3 w-full">
          <h1>
            <strong :style="{ color: statusData.color }">
              {{ civilCase.cid }} - {{ civilCase.customer.alias }}
            </strong>
          </h1>
          <h6>
            {{ $t(`enums.civilCaseStatuses.${statusData.key}`) }} |
            {{ $moment(civilCase.date.toDate()).format("DD-MM-YYYY HH:mm") }}
          </h6>
        </div>
        <div class="vx-col sm:w-2/3 w-full text-right">
          <h6>
            <strong>{{ civilCase.createdBy.email }}</strong>
            <br />
            {{
              $moment(civilCase.createdAt.toDate()).format("DD-MM-YYYY HH:mm")
            }}
          </h6>
        </div>
        <div class="vx-col sm:w-1/1 w-full mb-2 mt-2" v-if="lawyer">
          {{ lawyer.alias }}
          <a :href="`tel:${lawyer.phone}`" v-if="lawyer.phone">
            - <feather-icon icon="PhoneIcon" svgClasses="w-4 h-4" />
            {{ lawyer.phone }}</a
          >
          <a :href="`mailto:${lawyer.email}`" v-if="lawyer.email">
            - <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            {{ lawyer.email }}</a
          >
        </div>

        <vs-divider />
      </div>

      <vs-alert
        active="true"
        class="mt-5 mb-5"
        color="danger"
        icon-pack="feather"
        icon="icon-trash"
        v-if="civilCase && civilCase.deleted"
      >
        {{ $t("messages.errors.caseRemoved") }}
      </vs-alert>
    </template>
  </FormPage>
</template>
<script>
import { mapGetters } from "vuex";
import { db } from "@/firebase/firebaseConfig";

import FormPage from "@/components/penal-retail-helpers/FormPage";
import civilCasesFormConfig from "@/helpers/civilCasesFormConfig";

import statuses from "@/data/civilCases/civilCaseStatuses";

let collectionName = "civilCases";

export default {
  components: {
    FormPage,
  },
  computed: {
    ...mapGetters(["allCivilCases", "allCustomers", "allLawyers"]),
    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
    lawyer() {
      return this.lawyers && this.civilCase && this.civilCase.lawyer
        ? this.lawyers.find((x) => x.id === this.civilCase.lawyer.id)
        : null;
    },
    statusData() {
      return this.civilCase && this.civilCase.status
        ? statuses.find((x) => x.id === this.civilCase.status.id)
        : null;
    },
  },
  created() {
    this.$bind(
      "civilCase",
      db.collection("civilCases").doc(this.$route.params.id),
      { wait: true }
    );

    this.$bind(
      "files",
      db
        .collection("civilFiles")
        .where("entityId", "==", this.$route.params.id)
        .where("deleted", "==", false),
      { wait: true }
    );
  },
  data() {
    let rows = civilCasesFormConfig(
      this,
      this.$store.state.AppActiveUser.role,
      false
    );

    let fileAttributes = [];
    fileAttributes.push({
      size: "1/1",
      type: "files",
      attribute: "civilFiles",
      hideLabel: true,
      extra: {
        files: () => {
          return this.files;
        },
        config: {
          url: "/",
          uploadMultiple: true,
          parallelUploads: 5,
          method: "put",
          autoQueue: false,
          maxFilesize: 2048,
          dictDefaultMessage: this.$t("messages.filesDragAnddrop"),
        },
      },
      isReadonly: this.isCustomer || this.isSuperCustomer,
    });

    return {
      civilCase: null,
      files: null,
      customers: [],
      lawyers: [],
      collectionName,
      tabs: [
        { name: `${collectionName}.tabs.general`, rows },
        {
          name: `${collectionName}.tabs.files`,
          attributes: fileAttributes,
        },
      ],
    };
  },
  methods: {
    prevalidate() {
      return false;
    },
  },
};
</script>
